import React from "react";
import styled from "styled-components";
import { Wrapper, Container } from "../global";

import kerry from "../../images/staff/kerry.jpg";
import grant from "../../images/staff/grant.jpg";
import rachel from "../../images/staff/rachel.jpg";
import edward from "../../images/staff/edward.jpg";
import nick from "../../images/staff/nick.jpg";
import kodie from "../../images/staff/kodie.jpg";
import jordan from "../../images/staff/jordan.jpg";
import gabby from "../../images/staff/gabby.jpg";
import { Grid, Typography } from "@material-ui/core";

const staffInfo = [
  {
    name: "Kerry Spackman",
    title: "CEO & Founder",
    image: kerry,
    points: {
      point1: "Winner of the KEA New Zealand World Class Award 2010",
      point2: "Winner of the NEEDA Award for most significant Electronic Export from NZ",
      point3: "Winner of the Senior Prize in Applied Mathematics Auckland University",
      point4: "Ph.D.Cognitive Neuroscience – Psychophysics",
      point5:
        "Consultant to 4 Formula One Teams over 7 years: developed aerodynamic modelling, simulation, mathematical optimisation, training F- 1 drivers and developing simulators",
      point6: "Author of No.1 bestselling book, The Winner's Bible",
      point7: "Author of No.1 bestselling book, The Ant and the Ferrari",
      point8:
        "Founder and Director of the NZ Government's GoldMine Project, developing electronics and mathematical analysis for NZ’s Olympic Medallists",
      point9: "Wrote and is Co-Producer for the upcoming major international movie on Einstein, Einstein’s Compass",
      point10:
        "Founded and Developed the Vehicle Dynamics Engineer course for Ford Motor Co. World Headquarters along with partner 3-time F- 1 Champion, Sir Jackie Stewart",
      point11: "Adjunct Professor of Empirical Metaphysics",
      point12:
        "Wrote and Presented the Discovery TV feature, Speed Science which has been translated and shown around the world to over 300 million viewers",
    },
    paragraphs: {},
  },
  {
    name: "Grant Davidson",
    title: "Director of Software Development",
    image: grant,
    points: {},
    paragraphs: {
      paragraph1: "Grant’s role at CPL includes developing and maintaining the software powering Ako Maps.",
      paragraph2:
        "Prior to CPL, Grant lead the development of performance analysis systems for New Zealand's Olympic program.",
      paragraph3:
        "Grant has also worked with Kerry on a wide range of projects, including F1 track side performance optimisation software for Jaguar Racing.",
      paragraph4: "Grant has a Masters of Engineering from the University of Auckland",
    },
  },
  {
    name: "Rachel Meadows",
    title: "Operations Manager",
    image: rachel,
    points: {},
    paragraphs: {
      paragraph1: "Rachel’s role at CPL includes research, client liaison, and business analysis.",
      paragraph2:
        "She has conducted trials for use cases and works with CPL Studios to create educational and promotional content.",
      paragraph3: "Rachel has a BSci (Hons) in Psychology with first-class honours from the University of Auckland.",
      paragraph4: "She received the Dean’s award (Faculty of Culture and Society) in her undergraduate degree at AUT.",
    },
  },
  {
    name: "Edward Chen",
    title: "Senior Full Stack Developer",
    image: edward,
    points: {},
    paragraphs: {
      paragraph1: "Edward is a software engineer with 13 years of experience.",
      paragraph2:
        "Prior to CPL, Edward has worked in a number of organizations including Kiwiplan, University of Auckland and Orion Health.",
      paragraph3:
        "Edward has a bachelor degree of Information System and Computer Science from Massey University and Post Graduate Diploma of computer science from the University of Auckland.",
    },
  },
  {
    name: "Nick Dew",
    title: "VFX Artist / 3D Generalist",
    image: nick,
    points: {},
    paragraphs: {
      paragraph1:
        "Nick’s role at CPL includes working as a 3D generalist, VFX artist, with particular expertise in compositing and sound design.",
      paragraph2:
        "He has a Bachelor of Design in Digital Design from AUT, along with a background in videography, compositing, and brand ambassador work.",
      paragraph3:
        "Nick has received the 2019 AUT Visual Effects Award, was a finalist in The Designers Institute Best Design Awards 2019, and received a silver award in The Designers Institute Best Design Awards 2020.",
    },
  },
  {
    name: "Kodie Murray",
    title: "VFX Artist / Computer Technician",
    image: kodie,
    points: {},
    paragraphs: {
      paragraph1:
        "Kodie's role at CPL includes working as a VFX artist, with particular expertise in compositing, editing, and VFX.",
      paragraph2:
        "He has a background as an IT technician and programmer, and has produced, edited and created VFX for various projects, winning the 48 Hour Film Festival for the Taranaki Region.",
      paragraph3: "Kodie has a Bachelor of Information Technology from the Waikato Institute of Technology.",
    },
  },
  {
    name: "Jordan May",
    title: "Animator / 3D Generalist",
    image: jordan,
    points: {},
    paragraphs: {
      paragraph1:
        "Jordan’s role at CPL includes working as a 3D generalist, with particular expertise in animation, 3D design and VFX",
      paragraph2: "He has a background in character animation, photogrammetry and motion capture.",
      paragraph3: "Jordan has a Bachelor of Design in Digital Design from AUT.",
    },
  },
  {
    name: "Gabrielle McDonald",
    title: "Illustrator / Digital Designer",
    image: gabby,
    points: {},
    paragraphs: {
      paragraph1:
        "Gabrielle’s role at CPL includes creating illustrations and video assets, with particular expertise in motion design, designing graphics, and concept work.",
      paragraph2: "She has a background as a digital artist and has worked in the world of performing art.",
      paragraph3:
        "Gabrielle has a Bachelor of Design in Digital Design from AUT, which she completed with the Vice-Chancellor’s significant student award.",
    },
  },
];

const AboutStaff: React.FC = () => {
  return (
    <Wrapper id="about">
      <Container>
        {staffInfo.map(staff => (
          <GridContainer container key={staff.name} justify="center">
            <GridItem container item justify="center">
              <Grid item style={{ width: "80%" }}>
                <img src={staff.image} alt="" />
              </Grid>
            </GridItem>
            <GridItem item>
              <Typography variant="h4">{staff.name}</Typography>
              <Typography variant="h6" color="primary">
                {staff.title}
              </Typography>
              <BulletPoints>
                {Object.values(staff.points).map(point => (
                  <li key={point}>
                    <Typography variant="body1">{point}</Typography>
                  </li>
                ))}
              </BulletPoints>
              <ParagraphContainer>
                {Object.values(staff.paragraphs).map(paragraph => (
                  <Typography variant="body1" key={paragraph}>
                    {paragraph}
                  </Typography>
                ))}
              </ParagraphContainer>
            </GridItem>
          </GridContainer>
        ))}
      </Container>
    </Wrapper>
  );
};

export default AboutStaff;
const BulletPoints = styled.ul`
  > li {
    display: list-item;
  }
`;
const GridContainer = styled(Grid)`
  padding: 32px 0;
  min-width: 400px;
  & img {
    width: 100%;
    box-shadow: 5px 10px 10px #888888;
    border-radius: 8px;
    margin-bottom: 32px;
  }
`;
const GridItem = styled(Grid)`
  width: 50% !important;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 90% !important;
  }
`;
const ParagraphContainer = styled.div`
  > p {
    padding: 8px 0;
    color: #090909;
  }
  & li {
    color: #090909;
  }
`;
