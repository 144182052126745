import React from "react";
import styled from "styled-components";
import { WrapperColored, ContainerColored } from "../global";

import { Typography, Grid } from "@material-ui/core";

const AboutStaff: React.FC = () => {
  return (
    <WrapperColored id="about">
      <ContainerColored>
        <Grid container alignItems="center" justify="center">
          <GridItem item>
            <Typography variant="h3" style={{ fontWeight: 600 }}>
              Cognitive Performance Labs
            </Typography>
          </GridItem>
          <GridItem item>
            <Typography variant="h6">
              Cognitive Performance Labs is a joint venture partnership with one of New Zealand’s most significant
              companies, Dr Kerry Spackman, and Grant Davidson.
            </Typography>
          </GridItem>
        </Grid>
      </ContainerColored>
    </WrapperColored>
  );
};

export default AboutStaff;

const GridItem = styled(Grid)`
  padding: 0 8px 32px;
  width: 50%;
  color: white;
  -webkit-text-fill-color: white;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 80%;
  }
`;
